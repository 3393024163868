import { render, staticRenderFns } from "./ContractRentSidebar.vue?vue&type=template&id=195c0efd&scoped=true&"
import script from "./ContractRentSidebar.vue?vue&type=script&lang=js&"
export * from "./ContractRentSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195c0efd",
  null
  
)

export default component.exports